<template>
  <div
    class="register-company tw-flex tw-flex-col tw-flex-1"
  >
    <div class="register-company__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0">
      <div class="register-company__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12">
        <register-header
          :title="$t('app.titles.register.company')"
          :steps="steps"
          class="tw-mb-10"
          data-test="header"
        />

        <ValidationObserver
          ref="vat-observer"
          slim
        >
          <form
            :disabled="$wait.is('fetching VAT number') || (company && editCompany)"
            @submit.prevent="retrieveVAT"
          >
            <ValidationProvider
              ref="vat_number-provider"
              :name="$t('app.fields.vat_number')"
              :rules="'required|max:255'"
              slim
              class="register-company__search tw-flex tw-items-center tw-mb-5"
              data-test="vat-number-provider"
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="vatNumber"
                  v-model="formData.vatNumber"
                  :error="invalid && validated || errors && errors.length > 0"
                  :label="$t('app.labels.vat_number')"
                  :hint="errors[0]"
                  :loader="$wait.is('fetching VAT number')"
                  :disabled="$wait.is('fetching VAT number') || (company && editCompany)"
                  name="vat_number"
                  type="text"
                  class="register-company__search__field tw-flex-1"
                  data-test="vat_number"
                  required
                  @input="() => { editCompany = false; company = null }"
                />
                <UiButton
                  :loading="$wait.is('fetching VAT number')"
                  :disabled="$wait.is('fetching VAT number') || (company && editCompany)"
                  type="submit"
                  variant="primary"
                  class="register-company__search__button !tw-rounded-tl-none !tw-rounded-bl-none"
                  data-test="search"
                >
                  <template #left-icon>
                    <ui-ctk-icon
                      name="search"
                      data-test="icon"
                    />
                  </template>
                </UiButton>
              </template>
            </ValidationProvider>

            <!-- VAT notice -->
            <div
              v-if="!editCompany && !company"
              class="register-company__content__notice tw-rounded tw-flex tw-p-3 tw-mt-4 tw-mb-4"
              data-test="vat-notice"
            >
              <p
                v-text="$t('app.paragraphs.vat.shipper')"
                class="tw-flex-1 tw-mb-0"
              />
            </div>

            <!-- Not found VAT -->
            <div
              v-if="editCompany && searched && !company"
              class="register-company__content__notice tw-rounded tw-flex tw-p-2 tw-mt-4 tw-mb-4"
              data-test="not-found-vat-notice"
            >
              <ui-ctk-icon
                name="warning"
                class="tw-text-2xl tw-mr-1"
              />
              <p
                v-text="$t('app.paragraphs.no_vat_found')"
                class="tw-flex-1 tw-mb-0"
              />
            </div>
          </form>
        </ValidationObserver>

        <ValidationObserver
          ref="observer"
          slim
        >
          <template slot-scope="{ invalid }">
            <form
              v-if="editCompany"
              @submit.prevent="submitted"
            >
              <ValidationProvider
                ref="name-provider"
                :name="$t('app.fields.company_name')"
                :rules="'required'"
                slim
              >
                <template slot-scope="{ invalid: fieldInvalid, validated, errors }">
                  <ctk-input-text
                    id="name"
                    v-model="formData.name"
                    name="name"
                    type="text"
                    :label="$t('app.labels.company_name')"
                    :error="fieldInvalid && validated || errors && errors.length > 0"
                    :hint="errors[0]"
                    :loader="$wait.is('accepting quotation')"
                    :disabled="$wait.is('accepting quotation')"
                    data-test="name"
                    class="tw-mb-4"
                    required
                    autocomplete="organization"
                  />
                </template>
              </ValidationProvider>
              <ValidationProvider
                ref="street-provider"
                :name="$t('app.fields.street_name')"
                :rules="'required'"
                slim
              >
                <template slot-scope="{ invalid: fieldInvalid, validated, errors }">
                  <ctk-input-text
                    id="address"
                    v-model="formData.streetName"
                    name="address"
                    type="text"
                    :label="$t('app.labels.street_name')"
                    :error="fieldInvalid && validated || errors && errors.length > 0"
                    :hint="errors[0]"
                    :loader="$wait.is('accepting quotation')"
                    :disabled="$wait.is('accepting quotation')"
                    data-test="address"
                    class="tw-mb-4"
                    required
                    autocomplete="address-line1"
                  />
                </template>
              </ValidationProvider>
              <div class="tw-flex">
                <ValidationProvider
                  ref="postal_code-provider"
                  :name="$t('app.fields.postal_code')"
                  :rules="'required|max:10'"
                  slim
                  class="tw-w-1/2 tw-mr-4"
                >
                  <template slot-scope="{ invalid: fieldInvalid, validated, errors }">
                    <ctk-input-text
                      id="postalCode"
                      v-model="formData.postalCode"
                      name="postal-code"
                      type="text"
                      :label="$t('app.labels.postal_code')"
                      :error="fieldInvalid && validated || errors && errors.length > 0"
                      :hint="errors[0]"
                      :loader="$wait.is('accepting quotation')"
                      :disabled="$wait.is('accepting quotation')"
                      data-test="postal-code"
                      class="tw-mb-4"
                      required
                      autocomplete="postal-code"
                    />
                  </template>
                </ValidationProvider>
                <ValidationProvider
                  ref="city-provider"
                  :name="$t('app.fields.city')"
                  :rules="'required'"
                  slim
                  class="tw-w-1/2"
                >
                  <template slot-scope="{ invalid: fieldInvalid, validated, errors }">
                    <ctk-input-text
                      id="city"
                      v-model="formData.city"
                      name="city"
                      type="text"
                      :label="$t('app.labels.city')"
                      :error="fieldInvalid && validated || errors && errors.length > 0"
                      :hint="errors[0]"
                      :loader="$wait.is('accepting quotation')"
                      :disabled="$wait.is('accepting quotation')"
                      data-test="city"
                      class="tw-mb-4"
                      required
                      autocomplete="address-level2"
                    />
                  </template>
                </ValidationProvider>
              </div>
              <ValidationProvider
                ref="country-provider"
                :name="$t('app.fields.country')"
                rules="required|max:2"
                slim
              >
                <template slot-scope="{ invalid: fieldInvalid, validated, errors }">
                  <country-selector
                    id="country"
                    v-model="formData.country"
                    :label="$t('app.labels.country')"
                    :error="!$wait.is('fetching company') && fieldInvalid && validated || errors && errors.length > 0"
                    :hint="errors[0]"
                    :items="countries"
                    :data-vv-as="$t('app.fields.country')"
                    :loader="$wait.is('fetching company')"
                    :preferred-countries="true"
                    :disabled="true"
                    data-test="country-field"
                    class="tw-mb-4"
                    color="#287696"
                    has-name
                    type="text"
                    name="country"
                    required
                    autocomplete="country"
                  />
                </template>
              </ValidationProvider>

              <!-- Incomplete informations -->
              <div
                v-if="invalid && company && !company.address.isComplete"
                class="register-company__content__notice tw-rounded tw-flex tw-p-2 tw-mt-4 tw-mb-4"
                data-test="incomplete-vat-notice"
              >
                <ui-ctk-icon
                  name="warning"
                  class="tw-text-2xl tw-mr-1"
                />
                <p
                  v-text="$t('app.paragraphs.missing_vat_informations')"
                  class="tw-flex-1 tw-mb-0"
                />
              </div>
            </form>
          </template>
        </ValidationObserver>

        <company-card
          v-if="company && !editCompany && company.address.isComplete"
          :name="company.name"
          :address="company.address"
          @edit="edit"
        />
      </div>
    </div>
    <register-navigation
      :submit-disabled="!editCompany && !company"
      data-test="navigation"
      @back="back"
      @submit="submitted"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent, readonly } from '@vue/composition-api'

  import { Registration } from '@/resources'
  import { EventBus } from '@/services/EventBus'
  import { showToaster } from '@/services/Toaster'
  import handlePropertyPathViolations from '@/resources/handlers/violations'
  import usePhoneCodeCountries from '@/composables/usePhoneCodeCountries'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  // @ts-ignore
  import CountrySelector from '@/components/CtkPhoneNumber/_subs/CountrySelector'
  import Address from '@/models/Address'
  import RegisterNavigation from '@/views/Common/RegisterFlow/components/RegisterNavigation/index.vue'
  import RegisterHeader from '@/views/Common/RegisterFlow/components/RegisterHeader/index.vue'
  import CompanyCard from '@/views/Common/RegisterFlow/_subs/RegisterCompany/components/CompanyCard/index.vue'

  /**
   * @module view - RegisterCompany
   */
  export default defineComponent({
    name: 'RegisterCompany',
    components: {
      CtkInputText,
      CountrySelector,
      RegisterNavigation,
      RegisterHeader,
      CompanyCard
    },
    setup () {
      const { countries } = usePhoneCodeCountries()
      const countriesList = readonly(countries)

      return {
        countries: countriesList
      }
    },
    data () {
      return {
        editCompany: false,
        searched: false,
        isCard: false,
        /** @type {{
         *  vatNumber: string|null
         *  name: string|null
         *  streetName: string|null
         *  postalCode: string|null
         *  city: string|null
         *  country: string
         * }} */
        formData: {
          vatNumber: null,
          name: null,
          streetName: null,
          postalCode: null,
          city: null,
          country: 'FR'
        },
        /** @type {{ name: string, address: import('@/models/Address').default}|null} */ company: null
      }
    },
    mounted () {
      EventBus.$on('register:company:violations', this.updateViolations)

      /**
       * If we already have the company informations from a previous
       * navigation in the store, pre-fill the form with it's infos.
       */
      const company = this.getCompany
      if (company) {
        const { vatNumber, name, address, isCard, editCompany } = company
        const { streetName, city, postalCode, country } = address
        this.searched = false
        this.editCompany = editCompany
        this.isCard = isCard
        this.formData = {
          vatNumber,
          name,
          streetName,
          city,
          postalCode,
          country
        }
        this.company = {
          name,
          address
        }
      }
    },
    computed: {
      ...mapGetters('auth/register', [
        'getCompany',
        'getQuestions'
      ]),
      /**
       * @function steps
       * @returns {string}
       */
      steps () {
        return `1/${this.getQuestions.questions.length + 3}`
      }
    },
    methods: {
      ...mapActions('auth/register', [
        'setCompany'
      ]),
      /**
       * @function updateViolations
       * @param {Array<import('@/resources/handlers/violations').Violation>} violations
       */
      updateViolations (violations) {
        try {
          /**
           * @type {{[key: string]: string}}
           */
          const propertyMatch = {
            'address.street': 'street',
            'address.postal_code': 'postal_code',
            'address.city': 'city',
            'address.country': 'country',
            vat_number: 'vat_number',
            name: 'name'
          }

          /**
           * Make a little transformation to match the violation property path
           * and the front provider names.
           */
          handlePropertyPathViolations.call(this, violations.map(violation => {
            if (Object.keys(propertyMatch).includes(violation.property_path)) {
              violation.property_path = propertyMatch[violation.property_path]
            }

            return violation
          }))
        } catch (err) {
          showToaster(this, err.message, {
            type: 'error'
          })
        }
      },
      /**
       * @function reset
       */
      reset () {
        this.formData = {
          vatNumber: null,
          name: null,
          streetName: null,
          postalCode: null,
          city: null,
          country: 'FR'
        }
      },
      /**
       * @function edit
       */
      edit () {
        if (this.company && this.company.address) {
          const { streetName, city, postalCode, country } = this.company.address
          this.$set(this, 'formData', {
            ...this.formData,
            name: this.company.name,
            streetName,
            city,
            postalCode,
            country
          })
        }

        this.editCompany = true
      },
      /**
       * @function back
       */
      back () {
        this.searched = false
        if (this.editCompany && !this.company) {
          this.editCompany = false
          return
        } else if (!this.editCompany && this.company && this.company.address.isComplete) {
          this.company = null
          return
        } else if (!this.isCard && this.editCompany) {
          this.company = null
          this.editCompany = false
          this.formData = {
            vatNumber: this.formData.vatNumber,
            name: null,
            streetName: null,
            postalCode: null,
            city: null,
            country: 'FR'
          }
        } else {
          // @ts-ignore
          this.$router.push({
            name: 'Register'
          })
            .catch(() => {})
        }

        // @ts-ignore
        this.$refs['vat-observer'] && this.$refs['vat-observer'].reset()
      },
      retrieveVAT () {
        if (this.$matomo) this.$matomo.trackEvent('Register', 'Clicked', 'Search VAT')

        // @ts-ignore
        this.$refs['vat-observer'].validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            // @ts-ignore
            this.$wait.start('fetching VAT number')
            Registration.VAT({
              vat: this.formData.vatNumber
            })
              .then((res) => {
                const { address: { street, city, postal_code: postalCode, country }, name } = res.data

                const address = new Address(street, city, postalCode, country)
                this.company = {
                  name,
                  address
                }
                this.formData = {
                  vatNumber: this.formData.vatNumber,
                  name,
                  city,
                  streetName: street,
                  postalCode,
                  country
                }

                if (address.isComplete) this.isCard = true
                if (!address.isComplete) {
                  this.edit()

                  if (!name) this.company = null
                }
              })
              .catch((err) => {
                if (!err.response) return

                switch (err.response.status) {
                case 404:
                  this.editCompany = true
                  this.company = null
                  break
                case 400:
                  handlePropertyPathViolations.call(this, err.response.data.error.violations)
                  this.editCompany = false
                  this.company = null
                  break
                }
              })
              .finally(() => {
                this.searched = true

                // @ts-ignore
                this.$wait.end('fetching VAT number')
              })
          })
      },
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            // @ts-ignore
            const { vatNumber, name, streetName, city, postalCode, country } = this.formData

            let companyName
            let companyAddress

            if (this.company) {
              const { name, address } = this.company
              companyName = name
              companyAddress = address
            }

            if (this.editCompany && name) companyName = name
            companyAddress = new Address(streetName, city, postalCode, country)

            this.setCompany({
              vatNumber,
              name: companyName,
              address: companyAddress,
              isCard: this.isCard,
              editCompany: this.editCompany
            })

            this.$router.push({
              name: 'RegisterQuestions'
            })
              .catch(() => {})
          })
      }
    },
    beforeDestroy () {
      EventBus.$off('register:company:violations', this.updateViolations)
    }
  })
</script>

<style lang="scss">
.register-company__wrapper {
  width: 100%;
}
@media (min-width: 770px) {
  .register-company__wrapper {
    max-width: 300px;
  }
}
.register-company__content {
  margin-left: 0px;
  margin-right: 0px;
}
@media (min-width: 770px) {
  .register-company__content {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
.register-company__content__notice {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  background-color: $divider;
}
.register-company__search__field .ctk-input-text__field {
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}
.register-company__search__button {
  height: 42px;
  width: 42px;
}
</style>
