<template>
  <div
    class="register-confirmation tw-relative tw-flex tw-flex-col tw-flex-1"
  >
    <div class="register-confirmation__content tw-px-4 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto">
      <div
        class="register-confirmation__wrapper tw-flex tw-flex-col tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"
      >
        <img
          src="@/assets/img/icons/target.svg"
          alt=""
          width="70"
          height="70"
          class="tw-mx-auto tw-mb-6"
          data-test="icon"
        >
        <h1
          v-text="$t('app.titles.register.welcome')"
          class="tw-text-center tw-font-medium tw-text-2xl tw-mb-4"
          data-test="title"
        />
        <p
          v-text="$t('app.paragraphs.register.welcome_shipper')"
          class="tw-text-center tw-text-secondary-lighten tw-mb-6"
          data-test="content"
        />

        <ui-button
          :disabled="$wait.is('signing in')"
          :loading="$wait.is('signing in')"
          type="button"
          class="tw-mx-auto"
          variant="primary"

          v-matomo="{
            click: { category: 'Register', action: 'Clicked', name: 'Confirmation Member Area' }
          }"
          @click="confirm"
        >
          {{ $t('app.buttons.view_member_area') }}
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import store from '@/store'
  import { showToaster } from '@/services/Toaster'

  /**
   * @module view - RegisterConfirmation
   */
  export default defineComponent({
    name: 'RegisterConfirmation',
    computed: {
      ...mapGetters('auth/register', [
        'getAccount'
      ]),
      ...mapGetters('auth', [
        'isFirstConnection'
      ])
    },
    methods: {
      ...mapActions([
        'setAppReady'
      ]),
      ...mapActions('auth', [
        'signIn'
      ]),
      ...mapActions('ui', [
        'setWelcomeVisible'
      ]),
      confirm () {
        const { email, password } = this.getAccount

        this.$wait.start('signing in')
        this.signIn({
          email,
          password
        })
          .then(async () => {
            this.$router.push({
              name: 'Dashboard'
            })
              .catch(() => {})
              .finally(() => {
                this.setAppReady(true)
                this.$wait.end('signing in')

                if (this.isFirstConnection) setTimeout(this.setWelcomeVisible, 2000, true)
              })
          })
          // @ts-ignore
          .catch(err => {
            if (!err.response) return

            let message = this.$t('an_error_has_occurred')
            if (err && err.response && err.response.status === 401) {
              message = this.$t('auth.paragraphs.error.invalid_login')
            }

            showToaster(this, message, {
              type: 'error',
              position: 'bottom-left'
            })
          })
          .finally(() => {
            this.$wait.end('signing in')
          })
      }
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      const account = store.getters['auth/register/getAccount']
      if (account.email && account.password) {
        return next()
      }

      next({
        name: 'RegisterAccountCredentials',
        params: to.params
      })
    }
  })
</script>

<style lang="scss" scoped>
@media (min-width: 770px) {
  .register-confirmation::before {
    --tw-bg-opacity: 1;
    background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
    height: 0.5rem;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    content: '';
  }
}
.register-confirmation::after {
  margin-bottom: 2rem;
  bottom: 0px;
  content: '';
  background-image: url('~@/assets/img/illustrations/shipment_confirmation_large.svg');
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  height: 20%;
}
@media (min-width: 770px) {
  .register-confirmation::after {
    background-size: contain;
  }
}
@media only screen and (max-height: 700px) and (min-width: $breakpoint-tablet),
        only screen and (max-height: 450px) and (max-width: $breakpoint-tablet) {
  .register-confirmation::after {
    display: none;
  }
}
.register-confirmation__wrapper {
  max-width: 460px;
}
</style>
