var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-steps tw-flex tw-flex-col tw-bg-secondary-lighten"},[_c('div',{staticClass:"register-steps__content tw-flex tw-flex-col tw-justify-center lg:tw-w-2/3 tw-my-auto"},[_c('register-steps-item',{staticClass:"tw-mb-12",attrs:{"title":_vm.$t('auth.titles.register.company'),"description":_vm.$t('auth.paragraphs.register.company'),"progress":_vm.getCompanyProgress,"active":_vm.isCompanyActive.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var completed = ref.completed;
return [_c('img',{staticClass:"tw-m-auto",attrs:{"src":completed
            ? require('@/assets/img/icons/register/hotel/completed-animation.svg')
            : _vm.isCompanyActive.value
              ? require('@/assets/img/icons/register/hotel/in_progress.svg')
              : require('@/assets/img/icons/register/hotel/unstarted.svg'),"width":"50","height":"50"}})]}}])}),_c('register-steps-item',{staticClass:"tw-mb-12",attrs:{"title":_vm.$t('auth.titles.register.needs'),"description":_vm.$t('auth.paragraphs.register.needs'),"progress":_vm.getTransportProgress,"active":_vm.isTransportActive.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var completed = ref.completed;
return [_c('img',{staticClass:"tw-m-auto",attrs:{"src":completed
            ? require('@/assets/img/icons/register/truck/completed-animated.svg')
            : _vm.isTransportActive.value
              ? require('@/assets/img/icons/register/truck/in_progress.svg')
              : require('@/assets/img/icons/register/truck/unstarted.svg'),"width":"50","height":"50"}})]}}])}),_c('register-steps-item',{attrs:{"title":_vm.$t('auth.titles.register.about'),"description":_vm.$t('auth.paragraphs.register.about'),"progress":_vm.getAccountProgress,"active":_vm.isAccountActive.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var completed = ref.completed;
return [_c('img',{staticClass:"tw-m-auto",attrs:{"src":completed
            ? require('@/assets/img/icons/register/head/completed-animated.svg')
            : _vm.isAccountActive.value
              ? require('@/assets/img/icons/register/head/in_progress.svg')
              : require('@/assets/img/icons/register/head/unstarted.svg'),"width":"50","height":"50"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }