<template>
  <div
    class="register overflow-auto md:overflow-hidden tw-flex tw-flex-1 tw-flex-col md:tw-flex-row"
  >
    <div
      class="register__content tw-flex-1 tw-flex tw-flex-col"
      data-test="content"
    >
      <div
        class="register__content__header tw-flex tw-justify-center md:tw-justify-between tw-items-center tw-py-4 md:tw-pb-0 tw-px-4 md:tw-px-3"
        data-test="header"
      >
        <router-link
          :to="{ name: 'AuthView' }"
          class="auth-header__logo"
          data-test="logo"
        >
          <picture>
            <img
              src="@/assets/img/logo_bg_white.svg"
              alt="Logo Chronotruck"
              height="38"
              data-test="logo-image"
            >
          </picture>
        </router-link>
      </div>
      <div class="register__content__inner tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-relative tw-bg-white tw-rounded md:tw-shadow-lg md:tw-w-5/6 md:tw-my-auto md:tw-ml-auto">
        <router-view />
      </div>
    </div>
    <register-steps
      :class="{
        'register-steps--confirmation': $route.name === 'RegisterConfirmation'
      }"
      class="tw-hidden md:tw-flex"
      data-test="steps"
    />
  </div>
</template>

<script>
  import Vue from 'vue'

  import store from '@/store'
  import { i18n } from '@/locales'

  import RegisterSteps from '@/views/Common/RegisterFlow/components/RegisterSteps/index.vue'

  /**
   * @module view - RegisterFlow
   */
  export default {
    name: 'RegisterFlow',
    components: {
      RegisterSteps
    },
    // @ts-ignore
    metaInfo () {
      return {
        title: this.$t('auth.titles.register')
      }
    },
    // @ts-ignore
    beforeRouteLeave (to, from, next) {
      store.dispatch('auth/register/resetForm')
      next()
    },
    // @ts-ignore
    async beforeRouteEnter (to, from, next) {
      store.dispatch('wait/start', 'fetching questions', { root: true })
      try {
        await store.dispatch('auth/register/retrieveQuestions')
        store.dispatch('setAppReady', true)
        next()
      } catch (e) {
        // @ts-ignore
        Vue.toasted.error(i18n.t('an_error_has_occurred'), {
          position: 'bottom-right',
          action: {
            text: i18n.t('close'),
            // @ts-ignore
            onClick: (e, toast) => {
              toast.goAway(0)
            }
          }
        })
      }

      store.dispatch('wait/end', 'fetching questions', { root: true })
    }
  }
</script>

<style lang="scss" scoped>

  .register {
    background: #F5F5F5;

    &__content {
      flex: 1;

      &__header {
        flex: 0 0 55px;
      }

      &__inner {
        border: 1px solid $divider;
      }
    }

    .register-steps {
      flex: 0 0 40%;
    }

    @media only screen and (min-width: $breakpoint-tablet) {
      &__content {
        flex: 0 0 60%;

        &__inner {
          right: -2rem;
          height: 100%;
          max-height: 70%;
        }
      }
    }
  }

</style>

<style lang="scss">
.register-steps--confirmation .register-steps__content {
  display: none;
}
</style>
